<template>
  <div class="GalleryGroup" v-if="item && item.items">
    <GalleryFileUploader
      v-for="(item, index) in Go.arrayReverse(item.items)"
      :key="`${index}${item.id}`"
      :file="item"
      @delete="(data) => $emit('delete', data)"
    />
  </div>
</template>

<script>
export default {
  props: ["item"],
  components: {
    GalleryFileUploader: () => import("./GalleryFileUploader.vue"),
  },
};
</script>

<style lang="scss">
.GalleryGroup {
  position: relative;
  display: flex;
  gap: $mpadding/2;
  padding: 0 0 $mpadding/4 0;

  .GalleryFile {
    flex: 0.33;
  }
}
</style>
